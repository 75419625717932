interface CreateAccountData {
  username: string;
  publicKeys: HiveAccount["publicKeys"];
  referrer: string;
  ticket: string;
  recaptcha: string;
}

export const creationDataFn = async () =>
  fetch(`${import.meta.env.VITE_API_ENDPOINT}/creationData`).then((res) =>
    res.json(),
  );

export const checkReputationFn = async (
  recaptcha: () => Promise<string | undefined>,
) => {
  const token = await recaptcha();

  if (!token) {
    throw new Error("Recaptcha token is missing");
  }

  return fetch(`${import.meta.env.VITE_API_ENDPOINT}/checkReputation`, {
    method: "POST",
    headers: {
      recaptcha: token,
    },
  }).then((res) => res.json());
};

export const createAccountFn = async (data: CreateAccountData) => {
  const { recaptcha, ...accountData } = data;

  return fetch(`${import.meta.env.VITE_API_ENDPOINT}/createAccount`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      recaptcha,
    },
    body: JSON.stringify(accountData),
  }).then((res) => res.json());
};
