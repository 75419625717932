import React from "react";

import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

import App from "@/App";

import "@fontsource/noto-sans/300.css";
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/700.css";

import "@/style.css";

Sentry.init({
  dsn: "https://95a66c349931c911ae2d5e4f376b022e@o1123618.ingest.us.sentry.io/4507082763534336",
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/liketu\.com\/api/],
});

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
