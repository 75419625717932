import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import hive from "@hiveio/hive-js";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import _ from "lodash";
import { isMobile, isTablet } from "react-device-detect";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as Yup from "yup";

import { checkReputationFn, creationDataFn } from "@/api/signup.api";
import Form from "@/components/Form";
import Button from "@/components/Button";
import PageLoading from "@/components/PageLoading";
import ReferrerWrapper from "@/components/ReferrerWrapper";
import { AppContext } from "@/utils/AppContext";

const USERNAME_VALID_CHARS_REGEX = /^[a-z][a-z0-9-.]*[a-z0-9]$/;
const USERNAME_STRUCTURE_REGEX =
  /^[a-z][a-z0-9]{2,14}([.-]?[a-z0-9]{3,}){0,2}$/;
const USERNAME_STARTS_WITH_LOWERCASE_LETTER = /^[a-z]/;
const USERNAME_ENDS_WITH_NUMBER_OR_LOWERCASE_LETTER = /[a-z0-9]$/;

interface SignUpWrapperProps {
  referrerAccount: HiveAccount | null;
  referrer: string;
  account: HiveAccount;
  setAccount: React.Dispatch<React.SetStateAction<HiveAccount>>;
  suspended: boolean;
  setSuspended: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<PageName>>;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  ticket: string | null;
  setTicket: React.Dispatch<React.SetStateAction<string | null>>;
  debugMode: boolean;
}

interface PublicCreationData {
  accountTickets: number;
  lastAccountCreated: string;
  accountsCreatedToday: number;
  dailyCreateThreshold: number;
}

function SignUpWrapper({
  referrerAccount,
  referrer,
  account,
  setAccount,
  suspended,
  setSuspended,
  setCurrentPage,
  setError,
  ticket,
  setTicket,
  debugMode,
}: SignUpWrapperProps) {
  const [publicData, setPublicData] = useState<PublicCreationData>();
  const [referrerProfile, setReferrerProfile] = useState<ReferrerProfile>({});
  const [confirmed, setConfirmed] = useState(false);
  const [generatingAccount, setGeneratingAccount] = useState(false);
  const [typingUsername, setTypingUsername] = useState(false);
  const [checkingUsernameAvailability, setCheckingUsernameAvailability] =
    useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState(false);

  const liketuLink = useMemo(
    () =>
      isMobile || isTablet ? "https://m.liketu.com" : "https://liketu.com",
    [],
  );

  const { appLoading, setAppLoading } = useContext(AppContext);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    return executeRecaptcha();
  }, [executeRecaptcha]);

  const { isPending, isError, data } = useQuery({
    queryKey: ["creationData"],
    retry: 0,
    queryFn: creationDataFn,
  });

  const checkReputation = useMutation({
    mutationFn: checkReputationFn,
    retry: 0,
  });

  useEffect(() => {
    if (!isPending && !isError && data) {
      setPublicData(data);
    }
  }, [data, isPending, isError]);

  useEffect(() => {
    if (referrerAccount && referrerAccount.posting_json_metadata) {
      const referrerProfileCandidate: ReferrerProfile = {};

      try {
        const profileJSON = JSON.parse(
          referrerAccount.posting_json_metadata,
        ).profile;

        referrerProfileCandidate.account = referrer;

        if (Object.prototype.hasOwnProperty.call(profileJSON, "name")) {
          referrerProfileCandidate.name = profileJSON.name;
        }

        if (
          Object.prototype.hasOwnProperty.call(profileJSON, "profile_image")
        ) {
          referrerProfileCandidate.profile_image = profileJSON.profile_image;
        }

        if (Object.prototype.hasOwnProperty.call(profileJSON, "about")) {
          referrerProfileCandidate.about = profileJSON.about;
        }

        setReferrerProfile(referrerProfileCandidate);
      } catch (error) {
        referrerProfileCandidate.account = referrer;
        referrerProfileCandidate.name = referrerAccount.name;
        referrerProfileCandidate.profile_image = "";
        referrerProfileCandidate.about = "";

        setReferrerProfile(referrerProfileCandidate);
      }
    }
  }, [referrerAccount, referrer]);

  useEffect(() => {
    if (publicData || isError) {
      setAppLoading(false);
    }
  }, [publicData, isError]);

  const formik = useFormik({
    initialValues: {
      username: account.username,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "Username must contain at least 3 characters")
        .max(16, "Username cannot contain more than 16 characters")
        .matches(
          USERNAME_STARTS_WITH_LOWERCASE_LETTER,
          "Username must start with lowercase character 'a-z'",
        )
        .matches(
          USERNAME_ENDS_WITH_NUMBER_OR_LOWERCASE_LETTER,
          "Username must end with lowercase character 'a-z' or number '0-9'",
        )
        .matches(
          USERNAME_VALID_CHARS_REGEX,
          "Username contains invalid characters. Allowed: a-z, 0-9, . or -",
        )
        .matches(
          USERNAME_STRUCTURE_REGEX,
          "Username structure is invalid. Must have 3 characters before and after . or -",
        )
        .required("Username is required"),
    }),
    onSubmit: async (values) => {
      if (!generatingAccount) {
        setGeneratingAccount(true);

        if (confirmed) {
          const password = hive.formatter.createSuggestedPassword();

          setAccount({
            username: values.username,
            password: password,
            publicKeys: hive.auth.generateKeys(values.username, password, [
              "owner",
              "active",
              "posting",
              "memo",
            ]),
            privateKeys: hive.auth.getPrivateKeys(values.username, password, [
              "owner",
              "active",
              "posting",
              "memo",
            ]),
          });

          if (ticket || debugMode) {
            setCurrentPage("backup-account");
            setGeneratingAccount(false);
            return;
          } else {
            const result = await checkReputation.mutateAsync(
              handleReCaptchaVerify,
            );

            if (result.statusCode && result.statusCode === 400) {
              setError("Something went wrong");
              setCurrentPage("error-occurred");
              return;
            } else if (result.ticket && result.ticket !== "") {
              if (result.ticket === "BADREPUTATION") {
                setSuspended(true);
              } else {
                setTicket(result.ticket);
                setGeneratingAccount(false);
                setCurrentPage("backup-account");
              }

              return;
            }
            return;
          }
        } else {
          setError("Something went wrong");
          setCurrentPage("error-occurred");
        }
      }
    },
  });

  useEffect(() => {
    if (formik.values.username) {
      setTypingUsername(true);
    }

    const formikErrors = formik.errors.username;

    if (formikErrors) {
      return;
    }

    const resolve = async () => {
      const usernameError = hive.utils.validateAccountName(
        formik.values.username,
      );

      const result = await hive.api.lookupAccountNamesAsync([
        formik.values.username,
      ]);

      if (!usernameError) {
        setIsUsernameValid(true);

        if (formik.values.username !== "") {
          setConfirmed(true);
        }

        if (_.isEmpty(result[0])) {
          setIsUsernameAvailable(true);
          setCheckingUsernameAvailability(false);
        } else {
          setIsUsernameAvailable(false);
          setCheckingUsernameAvailability(false);
        }
      } else {
        setIsUsernameValid(false);
        setCheckingUsernameAvailability(false);
      }
    };

    const delayDebounce = setTimeout(() => {
      if (formik.values.username !== "") {
        setTypingUsername(false);
        setCheckingUsernameAvailability(true);
        resolve();
      }
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [formik.values.username, formik.errors.username]);

  return !appLoading ? (
    <Stack>
      {publicData &&
      publicData.accountTickets > 0 &&
      publicData.accountsCreatedToday < publicData.dailyCreateThreshold &&
      !suspended ? (
        <Stack className="w-full">
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 700,
              }}
            >
              Create, discover and engage - Get your free blockchain account
              today!
            </Typography>
          </Box>

          {referrerProfile.account && referrerProfile.account !== "liketu" && (
            <Stack justifyContent="center">
              <Typography
                variant="body2"
                sx={{ mt: 2, fontWeight: 700, textAlign: "center" }}
              >
                🥳 You have been referred by
              </Typography>
              <ReferrerWrapper referrer={referrerProfile} />
              <Typography
                variant="body2"
                sx={{ mt: 1, fontWeight: 700, textAlign: "center" }}
              >
                Join them on Liketu now!
              </Typography>
            </Stack>
          )}

          <Stack sx={{ mt: 4 }}>
            <Form
              className="h-full flex flex-col justify-center items-center"
              onSubmit={formik.handleSubmit}
            >
              <TextField
                id="username"
                hiddenLabel
                placeholder="Username"
                variant="filled"
                size="small"
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{"@"}</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {formik.values.username &&
                        (!typingUsername
                          ? !checkingUsernameAvailability
                            ? formik.errors.username
                              ? "❌"
                              : isUsernameValid
                                ? isUsernameAvailable
                                  ? "✅"
                                  : "❌"
                                : "❌"
                            : "🔎"
                          : "⌛️")}
                    </InputAdornment>
                  ),
                }}
                className="w-full"
                value={formik.values.username.toLowerCase()}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                disabled={generatingAccount}
              />
              {formik.values.username &&
                (formik.errors.username ? (
                  <Alert severity="error" variant="outlined" sx={{ mt: 1 }}>
                    {formik.errors.username as string}
                  </Alert>
                ) : (
                  <>
                    {!typingUsername && !checkingUsernameAvailability ? (
                      isUsernameValid ? (
                        isUsernameAvailable ? (
                          <Alert
                            severity="success"
                            sx={{ mt: 1 }}
                            className="w-full"
                          >
                            Username available
                          </Alert>
                        ) : (
                          <Alert
                            severity="error"
                            variant="outlined"
                            sx={{ mt: 1 }}
                            className="w-full"
                          >
                            Username is already taken
                          </Alert>
                        )
                      ) : (
                        <div>
                          <span>Username is not valid</span>
                        </div>
                      )
                    ) : (
                      <Alert
                        severity="warning"
                        variant="outlined"
                        sx={{ mt: 1 }}
                        className="w-full"
                      >
                        Checking for username availability
                      </Alert>
                    )}
                  </>
                ))}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="w-full mt-3"
              >
                <Link href={liketuLink} underline="hover" variant="body2">
                  Already have a HIVE account?
                </Link>
                <Button
                  type="submit"
                  variant="fill"
                  loading={generatingAccount}
                  disabled={
                    typingUsername ||
                    checkingUsernameAvailability ||
                    !isUsernameAvailable ||
                    !isUsernameValid ||
                    isError
                  }
                >
                  Next
                </Button>
              </Box>
            </Form>
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <Alert
            severity="error"
            variant="filled"
            sx={{ borderRadius: 999, marginTop: 2 }}
          >
            SERVICE UNAVAILABLE AT THE MOMENT
          </Alert>
          <Box textAlign="center" mt={3}>
            {suspended ? (
              <Stack>
                <Typography variant="body2">
                  We have detected that you have already used our service to
                  create an account.
                </Typography>
                <Typography variant="body2" mt={1}>
                  You can login at{" "}
                  <a href={liketuLink} className="text-[#6ac3ef] font-medium">
                    liketu.com
                  </a>{" "}
                  or use{" "}
                  <a
                    href="https://signup.hive.io"
                    className="text-[#6ac3ef] font-medium"
                  >
                    signup.hive.io
                  </a>{" "}
                  for other account creation options.
                </Typography>
                <Box mt={2}>
                  <Button variant="fill" href={liketuLink}>
                    Login Instead
                  </Button>
                </Box>
              </Stack>
            ) : (
              <Stack>
                <Typography variant="body2">
                  Our daily account creation limit has been reached.
                </Typography>
                <Typography variant="body2">
                  Please try again tomorrow or use{" "}
                  <a
                    href="https://signup.hive.io"
                    className="text-[#6ac3ef] font-medium"
                  >
                    signup.hive.io
                  </a>{" "}
                  for other account creation options.
                </Typography>
              </Stack>
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  ) : (
    <PageLoading />
  );
}

export default SignUpWrapper;
