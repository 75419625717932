import type {} from "@mui/lab/themeAugmentation";
import { PaletteMode, ThemeOptions } from "@mui/material";

export const getTheme = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: "#6ac3ef",
            contrastText: "#fff",
          },
          secondary: {
            main: "#34c6cb",
            contrastText: "#fff",
          },
          text: {
            primary: "#8D8D8D",
          },
          background: {
            default: "#FAFAFA",
          },
        }
      : {
          primary: {
            main: "#6ac3ef",
            contrastText: "#fff",
          },
          secondary: {
            main: "#34c6cb",
            contrastText: "#fff",
          },
        }),
  },
  typography: {
    fontFamily: ["Noto Sans", "sans-serif"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 999,
          textTransform: "unset",
          maxHeight: 24,
          fontSize: 14,
          fontWeight: 700,
          lineHeight: 17,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
          color: mode === "light" ? "#8D8D8D" : undefined,
        },
      },
    },
    MuiAppBar:
      mode === "light"
        ? {
            styleOverrides: {
              colorDefault: {
                backgroundColor: "#fff",
              },
            },
          }
        : {},
  },
});
