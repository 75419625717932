import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

function Footer() {
  const APP_VERSION = `v${import.meta.env.VITE_APP_VERSION}`;

  return (
    <AppBar
      position="fixed"
      component="footer"
      color="default"
      sx={{ top: "auto", bottom: 0 }}
    >
      <Toolbar>
        <Typography variant="body2" className="ml-auto">
          &copy; {new Date().getFullYear()} Liketu&nbsp;{" "}
          {APP_VERSION && (
            <>
              - <b>{APP_VERSION}</b>
            </>
          )}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
