import { useMemo, useState } from "react";

import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from "@/layouts/Default";

import Home from "@/pages/Home";

import { ColorModeContext } from "@/utils/ColorModeContext";
import { getTheme } from "@/utils/Theme";

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      children: [{ path: "/:referrer?", element: <Home /> }],
    },
  ],
  {
    basename: import.meta.env.BASE_URL,
  },
);

export default function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<PaletteMode>(
    prefersDarkMode ? "dark" : "light",
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light",
        );
      },
    }),
    [],
  );

  const theme = useMemo(() => createTheme(getTheme(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}
