import { useState } from "react";

import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Footer from "@/components/Footer";
import Header from "@/components/Header";

import { AppContext } from "@/utils/AppContext";
import { useTheme } from "@mui/material";

const queryClient = new QueryClient();

function DefaultLayout() {
  const [appLoading, setAppLoading] = useState(true);
  const theme = useTheme();

  return (
    <AppContext.Provider value={{ appLoading, setAppLoading }}>
      <GoogleReCaptchaProvider
        reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
        container={{
          parameters: {
            theme: theme.palette.mode === "dark" ? "dark" : "light",
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Box display="flex">
            {!appLoading && <Header />}
            <Box
              component="main"
              flexGrow={1}
              sx={{
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar />
              <Outlet />
              <Toolbar />
            </Box>
            {!appLoading && <Footer />}
          </Box>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </AppContext.Provider>
  );
}

export default DefaultLayout;
