import { Suspense, lazy, useEffect, useState } from "react";

import hive from "@hiveio/hive-js";
import Container from "@mui/material/Container";
import _ from "lodash";
import { useLocation, useParams } from "react-router-dom";

const AccountCreatedWrapper = lazy(
  () => import("@/components/AccountCreatedWrapper"),
);
const BackupAccountWrapper = lazy(
  () => import("@/components/BackupAccountWrapper"),
);
const ErrorOccurredWrapper = lazy(
  () => import("@/components/ErrorOccurredWrapper"),
);
import SignUpWrapper from "@/components/SignUpWrapper";
import PageLoading from "@/components/PageLoading";

function Home() {
  const location = useLocation();
  const params = useParams();

  const [account, setAccount] = useState<HiveAccount>({ username: "" });
  const [ticket, setTicket] = useState<string | null>(null);
  const [referrerAccount, setReferrerAccount] = useState<HiveAccount | null>(
    null,
  );
  const [referrer, setReferrer] = useState("");
  const [currentPage, setCurrentPage] = useState<PageName>("create-account");
  const [suspended, setSuspended] = useState(false);
  const [debugMode, setDebugMode] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const referrer = params.referrer;

    if (referrer) {
      hive.api.getAccounts(
        [referrer.replace(/@/g, "")],
        (_err: string, result: HiveAccount[]) => {
          if (result) {
            if (result.length === 1) {
              setReferrer(referrer.replace(/@/g, ""));
              setReferrerAccount(result[0]);
            }
          }
        },
      );
    }

    if (!_.isNil(query.get("debug_mode"))) {
      setDebugMode(query.get("debug_mode") === "true");
    }
  }, [params, location.search]);

  return (
    <Container
      maxWidth="xs"
      sx={{
        mt: 2,
        mb: 2,
        height: "auto",
      }}
    >
      <Suspense fallback={<PageLoading />}>
        {currentPage === "create-account" && (
          <SignUpWrapper
            referrer={referrer}
            referrerAccount={referrerAccount}
            suspended={suspended}
            setSuspended={setSuspended}
            setCurrentPage={setCurrentPage}
            account={account}
            setAccount={setAccount}
            setError={setError}
            ticket={ticket}
            setTicket={setTicket}
            debugMode={debugMode}
          />
        )}
        {currentPage === "backup-account" && (
          <BackupAccountWrapper
            referrer={referrer}
            setCurrentPage={setCurrentPage}
            account={account}
            debugMode={debugMode}
            setError={setError}
            ticket={ticket}
          />
        )}
        {currentPage === "account-created" && (
          <AccountCreatedWrapper account={account} debugMode={debugMode} />
        )}
        {currentPage === "error-occurred" && (
          <ErrorOccurredWrapper error={error} debugMode={debugMode} />
        )}
      </Suspense>
    </Container>
  );
}

export default Home;
