import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const PageLoading = () => {
  return (
    <Box
      display="flex"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      sx={{ height: "calc(100vh - 64px - 64px - 32px)" }}
    >
      <CircularProgress size={60} />
    </Box>
  );
};

export default PageLoading;
