import React, { useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";

interface ButtonProps {
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  variant?: "outlined" | "fill" | "text";
  width?: number | string;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  href?: string;
}

const Button = ({
  children,
  type,
  variant = "fill",
  width = "fit",
  id = "",
  className = "",
  style = {},
  loading = false,
  onClick,
  disabled,
  href,
}: ButtonProps) => {
  useEffect(() => {
    if (children === undefined) {
      console.error("Please provide a children value for Button");
    }
  }, []);

  return href ? (
    <a
      href={href}
      className={`w-[${width ? `${width}px` : ""}] text-sm text-center rounded-full py-[2px] px-[18px] font-bold select-none cursor-pointer text-[#6ac3ef] outline-none outline-0 ${variant === "outlined" ? "border-solid border-[1px] border-[#6ac3ef] hover:bg-[#00c3b81c]" : variant === "fill" ? "bg-[#6ac3ef] text-white border-solid border-[1px] border-[#6ac3ef] hover:border-[#47b6e9] hover:bg-[#47b6e9] disabled:border-[#6ac3ef] disabled:bg-[#6ac3ef]" : ""} disabled:opacity-50 disabled:cursor-default transition-all ${className}`}
    >
      {children}
    </a>
  ) : (
    <button
      type={type}
      id={id}
      className={`w-[${width ? `${width}px` : ""}] text-sm text-center rounded-full py-[2px] px-[18px] font-bold select-none cursor-pointer text-[#6ac3ef] outline-none outline-0 ${variant === "outlined" ? "border-solid border-[1px] border-[#6ac3ef] hover:bg-[#00c3b81c]" : variant === "fill" ? "bg-[#6ac3ef] text-white border-solid border-[1px] border-[#6ac3ef] hover:border-[#47b6e9] hover:bg-[#47b6e9] disabled:border-[#6ac3ef] disabled:bg-[#6ac3ef]" : ""} disabled:opacity-50 disabled:cursor-default transition-all ${className}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {!loading ? children : <CircularProgress color="inherit" size={14} />}
    </button>
  );
};

export default Button;
