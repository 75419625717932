import { useContext, useMemo } from "react";

import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { isMobile, isTablet } from "react-device-detect";

import { ColorModeContext } from "@/utils/ColorModeContext";

import LogoIcon from "@/assets/icon.svg?react";
import Logo from "@/assets/logo.svg?react";
import LogoWhite from "@/assets/logo_white.svg?react";

function Header() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const liketuLink = useMemo(
    () =>
      isMobile || isTablet ? "https://m.liketu.com" : "https://liketu.com",
    [],
  );

  return (
    <AppBar color="default" position="fixed">
      <Toolbar>
        <Link href={liketuLink} sx={{ flex: 1 }}>
          {isMobile ? (
            <LogoIcon className="h-[40px] cursor-pointer" />
          ) : theme.palette.mode === "dark" ? (
            <LogoWhite className="h-[40px] cursor-pointer" />
          ) : (
            <Logo className="h-[40px] cursor-pointer" />
          )}
        </Link>
        <Typography
          variant="h6"
          component="div"
          sx={{ textAlign: "center", fontWeight: 700 }}
        >
          Sign Up
        </Typography>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={colorMode.toggleColorMode} color="inherit">
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
